import Tile from "components/Tiles/Tile"
import Person from "./Person"
import Map from "components/map/Map"
import TagContainer from "Tags/TagContainer"
import AssignmentTag, { AssignmentTagProps } from "Assignments/AssignmentTag"
import Spacer from "components/Spacer/Spacer"
import { H6 } from "components/labels/Label"
import AddressRow from "Addresses/AddressRow"
import Column from "components/Column"
import Theme from "Theming/Theme"
import useModal from "components/modal/useModal"
import ContactDetails from "ContactDetails/ContactDetails"
import ContactDetailsRows from "ContactDetails/ContactDetailsRows"
import { useCreatePersonContactDetails } from "./PersonApi"
import PersonAddressModal from "./PersonAddressModal"
import PersonContactDetailsModal from "./PersonContactDetailsModal"
import Address from "Addresses/Address"
import { ModalContext } from "components/modal/ModalContext"
import { useCallback, useContext, useMemo, useState } from "react"
import { Tag } from "Tags/Tag"
import moment from "moment"
import { useAssignments } from "Assignments/AssignmentApi"
import MapMarkers from "components/map/MapMarkers"
import { generatePersonMapMarker } from "./usePersonUtility"


type PersonLocationTileProps = {
    person:Person,
}

export default function PersonLocationTile({
    person,
}:PersonLocationTileProps) {

    const [hoveredPersonId, setHoveredPersonId] = useState(null)

    const assignmentParams = useMemo(() => ({
        periodStart: moment().format('YYYY-MM-DD'),
        customer_id: person?.customer?.person_id,
        employee_id: person?.employee?.person_id
    }), [person])

    const assignments = useAssignments(assignmentParams)
    const addressModal = useModal<Address>()
    const contactDetailsModal = useModal<ContactDetails>()
    const createContactDetails = useCreatePersonContactDetails(person?.id)
    const { assignmentModal } = useContext(ModalContext)

    const createAssignment = useCallback(() => {
        var modalParams =  {
            customer_id: person?.customer?.person_id,
            employee_id: person?.employee?.person_id,
            start_date: moment().format('YYYY-MM-DD')
        }
        assignmentModal.select(modalParams)
    }, [person, assignmentModal.select])

    const personMarker = useMemo(() => generatePersonMapMarker(person, { markerClass: 'active'}), [person])

    const assignmentMarkers = useMemo(() => {
        return assignments.data?.map(assignment => {
            if(assignment.customer_id === person?.id) {
                return generatePersonMapMarker({...assignment.employee, employee: {}})
            }
            return generatePersonMapMarker({ ...assignment.customer, customer: {} })
        })
    }, [assignments, person])

    const assignmentTags:AssignmentTagProps[] = useMemo(() => {
        return assignments.data?.map(assignment => {
            return {
                assignment,
                mode: assignment.employee_id === person?.id ? 'employee' : 'customer',
                onMouseEnter: () => setHoveredPersonId(assignment.customer_id),
                onMouseLeave: () => setHoveredPersonId(null)
            }
        })
    }, [assignments.data])

    return (
        <Tile>
            <PersonAddressModal {...addressModal} personId={person?.id}/>
            <PersonContactDetailsModal {...contactDetailsModal} personId={person?.id}/>
            <Map initialPosition={personMarker?.geoposition}>
                { personMarker?.geoposition && (
                    <MapMarkers data={[personMarker]} />
                )}
                <MapMarkers data={assignmentMarkers} />
            </Map>
            <Spacer medium>
                <Column gap='large'>
                    <Column gap='medium'>
                        <AddressRow
                            address={person?.address}
                            onClick={() => addressModal.select(person?.address)}
                        />
                        <ContactDetailsRows 
                            contactDetails={person?.contactDetails} 
                            isPending={false} 
                            onClick={() => contactDetailsModal.select(person?.contactDetails)}
                            onClickCreate={() => createContactDetails.mutate({})}
                        />
                    </Column>
                    <Column gap={Theme.spacing.vMedium}>
                        <H6>Zuweisungen</H6>
                        <TagContainer>
                            <Tag
                                text="+ Neue Zuweisung"
                                backgroundColor='secondary'
                                color='white'
                                onClick={createAssignment}
                            />
                            {
                                assignmentTags?.map((props, index) => (
                                    <AssignmentTag { ...props} key={index}/>
                                ))
                            }
                        </TagContainer>
                    </Column>
                </Column>
            </Spacer>
        </Tile>
    )
}