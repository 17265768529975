import { HoverProvider, useHoverContext } from "./HoverContext";


export default function Hoverable({...props}) {

    return (
        <HoverProvider>
            <Content {...props}/>
        </HoverProvider>
    )
}

const Content = ({...props}) => {

    const { setHovered } = useHoverContext()

    return (
        <div 
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
            {...props}
        />
    )
}