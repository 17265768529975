import DataTypes from "Common/Objects/DataTypes";
import { ObjectPropertyDefinition } from "Common/Objects/PropertyDefinition";
import useObject from "Common/Objects/useObject";
import moment from "moment";

const paramDefinition: ObjectPropertyDefinition = {
    label: "Params",
    type: 'object',
    properties: {
        'periodStart': {
            type: 'single',
            label: 'Von',
            valueType: new DataTypes.Date()
        },
        'periodEnd': {
            type: 'single',
            label: 'Bis',
            valueType: new DataTypes.Date()
        },
        'isCustomer': {
            type: 'single',
            label: 'Kunde',
            valueType: new DataTypes.Boolean()
        },
        'isEmployee': {
            type: 'single',
            label: 'Mitarbeiter',
            valueType: new DataTypes.Boolean()
        }
    }
}

const initialParams = {
    periodStart: moment().format('YYYY-MM-DD'),
    periodEnd: moment().format('YYYY-MM-DD'),
}

export default function() {
    const params = useObject(paramDefinition, initialParams)

    return params
}