import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import ameli from "Api/ameli"
import Assignment from "./Assignment"

export type AssignmentGetParams = Partial<Assignment> & {
    periodStart?: string
    periodEnd?:string
}

export function useAssignments(params: AssignmentGetParams = {}) {

    return useQuery({
        queryKey: ['assignments', params],
        queryFn: () => ameli.get<Assignment[]>('/assignments', { params }).then(res => res.data)
    })
}

export function useAssignmentMutation<T>(mutationFn, callback=()=>{}) {

    const queryClient = useQueryClient()

    return useMutation<T, Error, T>({
        mutationFn,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['assignments'] })
        },
        onSettled: () => {
            callback()
        }
    })
}


export const useAssignmentCreate = (callback = () => { }) => {
    return useAssignmentMutation<Assignment>(
        (data: Assignment) => ameli.post('assignments', data),
        callback
    )
}


export const useAssignmentUpdate = (callback = () => { }) => {
    return useAssignmentMutation<Assignment>(
        (data: Assignment) => ameli.put('assignments', data),
        callback
    )
}

export const useAssignmentDelete = (callback = () => { }) => {
    return useAssignmentMutation<number>(
        (assignmentId: number) => ameli.delete(`assignments/${assignmentId}`),
        callback
    )
}