import IconRow from "components/Icons/IconRow"

interface Props {
    email:string | null,
    onClick?: () => any
}

export default function EmailRow({email, onClick}:Props) {
    if(!email) {
        return null
    }

    return (
        <IconRow
            onClick={onClick}
            iconPath='fa/FaEnvelope'
            text={email}
        />
    )
}