import BudgetTypeSelect from "./BudgetTypeSelect";
import DatePicker from "../components/input/DatePicker";
import EuroInput from "../components/input/euro-input";
import Row from "components/Row";
import TextArea from 'components/input/TextArea';
import { FormGroup } from "components/form/FormComponents";
import InputLabel from "components/input/InputLabel";
import IFormProps from "Forms/IFormProps";
import BudgetType from "./BudgetType";
import useBudgetUtility from "./useBudgetUtility";
import moment from "moment";

interface Props extends IFormProps {
    careLevel: number
}

export default function BudgetCreateForm({form, careLevel}:Props) {

    const budgetUtility = useBudgetUtility()

    const handleTypeChange = (type:BudgetType) => {
        const ownerId = form.getFieldValue('owner_id')
        if(type) {
            form.setFieldValue('budget_type_id', type.id),
                form.setFieldValue('steps', type.steps),
            form.setFieldValue('decline_code', type.decline_code),
            form.setFieldValue('amount', budgetUtility.getDefaultAmount(type.id, ownerId)),
            form.setFieldValue('period_begin', budgetUtility.suggestPeriodStart(type.id, moment()).format('YYYY-MM-DD')),
            form.setFieldValue('period_end', budgetUtility.suggestPeriodEnd(type.id, moment()).format('YYYY-MM-DD'))
        }
    }

    return(
        <div>
            <FormGroup>
                <BudgetTypeSelect 
                    label='Typ'
                    careLevel={careLevel}
                    name='budget_type_id'
                    value={form.getFieldValue('budget_type_id')}
                    onValueChange={handleTypeChange}
                />
                <EuroInput
                    label='Betrag'
                    type="text"
                    name='amount'
                    value={form.getFieldValue('amount')}
                    onChange={(event) => form.setFieldValue('amount', event.currentTarget.value)}
                />
                <Row>
                    <DatePicker
                        grow
                        label="Von"
                        type="text"
                        name='period_begin'
                        value={form.getFieldValue('period_begin')}
                        onChange={(value) => form.setFieldValue('period_begin', value)}
                    />
                    <InputLabel>-</InputLabel>
                    <DatePicker 
                        grow
                        label='Bis'
                        type="text"
                        name='period_end'
                        value={form.getFieldValue('period_end')}
                        onChange={(value) => form.setFieldValue('period_end', value)}
                    />
                </Row>
                <TextArea 
                    label='Anmerkung'
                    minRows={3}
                    name='note'
                    value={form.getFieldValue('note')}
                    onChange={(event) => form.setFieldValue('note', event.currentTarget.value)}
                />
            </FormGroup>
        </div>
    )
}