import './app.css'
import CustomerScreen from "app/Views/Customers/CustomerScreen";
import EmployeeScreen from "app/Views/Employees/EmployeeScreen";
import FinanceView from "app/Views/Finances/UnpaidBillsView";
import HomeView from "app/Views/HomeView/HomeView";
import MapView from "app/Views/Map/MapView";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import LoginView from "app/Views/Login/LoginView";
import EmployeeManagementScreen from "./Views/EmployeeManagement/EmployeeManagementScreen";
import OperationView from './Views/EmployeeManagement/OperationView';
import { AuthProvider, AuthContext } from "Login/AuthContext";
import { useContext } from 'react';
import { CustomerProvider } from '../Customer/CustomerContext';
import { HealthInsuranceProvider } from '../HealthInsurances/HealthInsuranceContext';
import { EmployeeProvider } from '../Employees/EmployeeContext';
import OperationReportCreateView from '../OperationReports/OperationReportCreateView';
import EmployeeManagementOverview from '../EmployeeManagement/EmployeeManagementOverview';
import AbsenceView from '../Absences/AbsenceView';
import CustomerInfo from '../Customer/CustomerInfo';
import CustomerProtocolView from './Views/Customers/CustomerProtocolView';
import CustomerOperations from './Views/Customers/CustomerOperations';
import CustomerBudgets from './Views/Customers/CustomerBudgets';
import CustomerBills from './Views/Customers/CustomerBills';
import EmployeeDocuments from './Views/Employees/EmployeeDocuments';
import EmployeeOperations from './Views/Employees/EmployeeOperations';
import EmployeeInfo from '../Employees/EmployeeInfo';
import { SignalProvider } from 'Signals/SignalContext';
import { ModalProvider } from 'components/modal/ModalContext';
import EmployeeAbsences from './Views/Employees/EmployeeAbsences';
import { ApiDataProvider } from 'Api/ApiDataContext';
import VacationRequestScreen from 'Vacations/Requests/VacationRequestScreen';
import { ErrorProvider } from 'Errors/ErrorContext';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import DataScreen from './Views/Data/DataScreen';
import AddressTable from 'Addresses/AddressTable';
import ServiceView from 'Services/ServiceView';
import { ApiProvider } from 'Common/Api/ApiContext';
import ameli from 'Api/ameli';
import NewApp from './NewApp'
import Home from './Views/Home/Home';
import { ThemeProvider } from 'styled-components';
import Theme from 'Theming/Theme';
import Finances from './Views/Finances/Finances';
import PersonView from './Views/Persons/PersonView';

const RootNavigation = () => {
    const { state: { token } } = useContext(AuthContext)

    if(token === null) {
        return (
            <LoginView />
        )
    }

    return (
        <>
            <Routes>
                <Route index element={<HomeView />} />
                <Route path='/data' element={<DataScreen/>}>
                    <Route index element={<AddressTable />} />
                    <Route path='Dienstleistungen' element={<ServiceView />} />
                </Route>
                <Route path='/finances' element={<FinanceView />} />
                <Route path='/customers' element={<CustomerScreen />}>
                    <Route index element={<CustomerInfo />} />
                    <Route path='Informationen' element={<CustomerInfo />} />
                    <Route path='Protokolle' element={<CustomerProtocolView />} />
                    <Route path='Budgets' element={<CustomerBudgets />} />
                    <Route path='Einsaetze' element={<CustomerOperations />} />
                    <Route path='Rechnungen' element={<CustomerBills />} />
                </Route>
                <Route path='/employees' element={<EmployeeScreen />}>
                    <Route index element={<EmployeeInfo />} />
                    <Route path='Informationen' element={<EmployeeInfo />} />
                    <Route path='Einsaetze' element={<EmployeeOperations />} />
                    <Route path='Dokumente' element={<EmployeeDocuments />} />
                    <Route path='Dokumente/Stundenbeleg' element={<OperationReportCreateView />} />
                    <Route path='Abwesenheiten' element={<EmployeeAbsences />} />
                </Route>
                <Route path='/operations' element={<OperationView />} />
                <Route path='/Personalplanung' element={<EmployeeManagementScreen />}>
                    <Route index element={<EmployeeManagementOverview />} />
                    <Route path='Abwesenheiten' element={<AbsenceView />} />
                    <Route path='Urlaubsanträge' element={<VacationRequestScreen/>}/>
                </Route>
                <Route path='/3.0' element={<NewApp />}>
                    <Route index element={<Home />} />
                    <Route path='Finanzen' element={<Finances />} />
                    <Route path='Personen' element={<PersonView />} />
                </Route>
                <Route path='/map' element={<MapView />} />
            </Routes>
        </>
    )
}

const CUSTOMER_PARAMS = {
    is_customer: true
}

const EMPLOYEE_PARAMS = {
    is_employee: true
}

const queryClient = new QueryClient()

export default function App() {
   
    return (
        <ApiProvider axiosInstance={ameli}>
            <QueryClientProvider client={queryClient}>
                <BrowserRouter>
                    <ErrorProvider>
                        <SignalProvider>
                            <ThemeProvider theme={Theme}>
                                <ApiDataProvider>
                                    <HealthInsuranceProvider>
                                        <CustomerProvider params={CUSTOMER_PARAMS}>
                                            <EmployeeProvider params={EMPLOYEE_PARAMS}>
                                                <AuthProvider>
                                                    <ModalProvider>
                                                        <RootNavigation/>
                                                    </ModalProvider>
                                                </AuthProvider>
                                            </EmployeeProvider>
                                        </CustomerProvider>
                                    </HealthInsuranceProvider>
                                </ApiDataProvider>
                            </ThemeProvider>
                        </SignalProvider>
                    </ErrorProvider>
                </BrowserRouter>
            </QueryClientProvider>
        </ApiProvider>
    );
}