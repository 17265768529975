import ContactDetails from "./ContactDetails";
import IconRow from "components/Icons/IconRow";
import usePhoneNumbers from "PhoneNumbers/usePhoneNumbers";
import PhoneNumberIconRow from "PhoneNumbers/PhoneNumberIconRow";
import EmailRow from "./EmailRow";
import WebsiteRow from "./WebsiteRow";
import ClickableOpacity from "components/Decorator/ClickableOpacity";
import Column from "components/Column";

interface Props {
    contactDetails:ContactDetails,
    isPending:boolean,
    onClick?:()=>any,
    onClickCreate?:()=>any
}

export default function ContactDetailsRows({ contactDetails, isPending, onClick, onClickCreate }:Props) {

    const phoneNumbers = usePhoneNumbers(contactDetails?.id)

    const {
        email=null,
        website=null
    } = contactDetails || {}

    if(!contactDetails) {
        return (
            <IconRow
                onClick={onClickCreate}
                iconPath='fa/FaAddressBook'
                text='Kontaktdaten hinzufügen'
            />
        )
    }

    if(isPending || phoneNumbers.isPending) {
        return null
    }

    if(!email && !website && phoneNumbers.data.length === 0) {
        return (
            <IconRow
                onClick={onClick}
                iconPath='fa/FaAddressBook'
                text='Kontaktdaten bearbeiten'
            />
        )
    }

    return (
        <ClickableOpacity onClick={onClick}>
            <Column gap='medium'>
                <EmailRow email={contactDetails.email}/>
                <WebsiteRow website={contactDetails.website}/>
                <PhoneNumberIconRow phoneNumbers={phoneNumbers.data}/>
            </Column>
        </ClickableOpacity>
    )
}