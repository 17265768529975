import DenominationLabel from "Denominations/DenominationLabel"
import FamilyStatusLabel from "FamilyStatus/FamilyStatusLabel"
import NationalityLabel from "Nationalities/NationalityLabel"
import TileSection from "components/TileSection"
import Label from "components/labels/Label"
import PeriodLabel from "components/labels/PeriodLabel"
import BirthdayLabel from "./BirthdayLabel"
import Person from "./Person"
import usePersonUtility from "./usePersonUtility"
import HealthInsuranceLabel from "HealthInsurances/HealthInsuranceLabel"

interface Props {
    person: Person
}

const PersonTileSections = ({ person }: Props) => {

    const { isActive } = usePersonUtility()

    if(!person) {
        return <Label>Person nicht gefunden</Label>
    }

    return (
        <>
            <TileSection label='Anrede' visible={Boolean(person.title_id)}>
                <Label>{ person?.title?.title_label}</Label>
            </TileSection>
            <TileSection label='Name'>
                <Label>{`${person.first_name} ${person.last_name}`}</Label>
            </TileSection>
            <TileSection label='Geburtstag' visible={Boolean(person.birthday)}>
                <BirthdayLabel date={person.birthday} />
            </TileSection>
            <TileSection label='Geburtsort' visible={Boolean(person.birthplace)}>
                <Label>{person.birthplace}</Label>
            </TileSection>
            <TileSection label='Nationalität' visible={person.nationality_id != null}>
                <NationalityLabel nationalityId={person.nationality_id} />
            </TileSection>
            <TileSection label='Familienstand' visible={Boolean(person.family_status_id)}>
                <FamilyStatusLabel familyStatusId={person.family_status_id} />
            </TileSection>
            <TileSection label='Konfession' visible={Boolean(person.denomination_id)}>
                <DenominationLabel denominationId={person.denomination_id} />
            </TileSection>
            <TileSection label='Krankenversicherung' visible={Boolean(person.health_insurance_id)}>
                <HealthInsuranceLabel id={person.health_insurance_id}/>
            </TileSection>
            <TileSection label='Versicherungsnummer' visible={Boolean(person.health_insurance_number)}>
                <Label>{person.health_insurance_number}</Label>
            </TileSection>
            {
                !isActive(person) && (
                    <>
                        <TileSection label='Dienstzeitraum' visible={Boolean(person.entry) || Boolean(person.exit_date)}>
                            <PeriodLabel start={person.entry} end={person.exit_date} />
                        </TileSection>
                        <TileSection label='Beendigungsdetails' visible={Boolean(person.cancelation_details)}>
                            <Label allowWhiteSpace>{person.cancelation_details}</Label>
                        </TileSection>
                    </>
                )
            }         
        </>
    )
}

export default PersonTileSections