import tt from "@tomtom-international/web-sdk-maps";
import { useEffect, useRef, useState } from "react";
import { MapProvider } from "./MapContext";


interface Props {
    initialZoom?:number,
    initialPosition?:[number, number],
    children?:React.ReactNode
}


export default function Map({ 
    initialZoom = 10,
    initialPosition = [7.2, 51.5],
    children,
    ...props
 } : Props) {

    const mapElement = useRef();
    const [map, setMap] = useState<tt.Map>(null);

    useEffect(() => {
        let map = tt.map({
            key: process.env.REACT_APP_TOM_TOM_API_KEY,
            container: mapElement.current,
            center: initialPosition,
            zoom: initialZoom,
            language: 'DE'
        });

        setMap(map);

        return () => {
            map.remove()
        };
    }, [mapElement]);

    useEffect(() => {
        if(map && initialPosition) {
            map.setCenter(initialPosition)
        }
    }, [map, initialPosition])

    return (
        <div
            ref={mapElement}
            style={{ position: 'relative', flexGrow: 1, minHeight: "300px", minWidth: '300px' }}
            {...props}
        >
            <MapProvider map={map}>
                { children }
            </MapProvider>
        </div>
    )
}