import { useEffect, useMemo } from "react";
import { useMapContext } from "./MapContext";
import { generateMarker, MapMarkerProps } from "./MapMarker";

interface MapMarkersProps {
    data: MapMarkerProps[]
}

export default function MapMarkers({ data } : MapMarkersProps) {

    const { map } = useMapContext()

    const markers = useMemo(() => {
        if(!data) {
            return []
        }
        return data
            .filter(item => item != null)
            .map(item => generateMarker(item))
            .filter(item => item != null)
    }, [data, map])

    useEffect(() => {
        if(map) {
            markers.forEach(marker => marker.addTo(map))
        }
        return () => {
            markers.forEach(marker => marker.remove())
        }

    }, [markers, map])

    return null
}