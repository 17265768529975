import IconRow from "components/Icons/IconRow"

interface Props {
    website:string | null,
    onClick?: () => any
}

export default function WebsiteRow({website, onClick}:Props) {
    if (!website) {
        return null
    }

    return (
        <IconRow
            onClick={onClick}
            iconPath='fa/FaGlobe'
            text={website}
        />
    )
}