import ActionIcons from 'app/Actions/ActionIcons'
import Action from 'Common/Actions/Action'
import ClickableOpacity from 'components/Decorator/ClickableOpacity'
import Hoverable from 'components/Hovering/Hoverable'
import HoverOpacity from 'components/Hovering/HoverOpacity'
import styled from 'styled-components'
import Theme from 'Theming/Theme'

const Container = styled.span`
    border-radius: 8px;
    color: ${props => props.theme.colors[props.color] || '#555'};
    background-color: ${props => props.theme.colors[props.backgroundColor] || '#dadada'};
    padding: 0.25em 1em;
    font-size: 0.75em;
    height: 19.2px;
    display: inline-flex;
    flex-direction: row;
    gap: ${props => props.theme.spacing.hSmall};
    align-items: center;
    justify-content: center;
`

const Text = styled.div`
    font-size: 13px;
    white-space: nowrap;
`

export type TagProps = {
    text: string,
    color?: string,
    backgroundColor?: string,
    actions?: Action[],
    onClick?:() => any
}

const Tag = ({ text, actions, onClick, ...props}:TagProps) => {

    return (
        <Hoverable>
            <Container {...props}>
                <ClickableOpacity onClick={onClick}>
                    <Text>{text}</Text>
                </ClickableOpacity>
                <HoverOpacity>
                    <ActionIcons 
                        size='14px'
                        color={Theme.colors.subtleText}
                        actions={actions}
                    />
                </HoverOpacity>
            </Container>
        </Hoverable>
    )
}

const SecondaryTag = (props) => {
    
    return (
        <Tag 
            color={Theme.whiteColor} 
            backgroundColor={Theme.secondaryColor} 
            {...props}
        />
    )
}


export {
    Tag,
    SecondaryTag
}