import tt from "@tomtom-international/web-sdk-maps"
import React from "react"
import { useContext } from "react"


type MapContextValue = {
    map: tt.Map,
}

const MapContext = React.createContext<MapContextValue>({
    map:null
})

type MapProviderProps = {
    map: tt.Map,
    children?: React.ReactNode
}

const MapProvider = ({ map, ...props }:MapProviderProps) => {

    return (
        <MapContext.Provider value={{ map }} {...props}/>
    )
}

const useMapContext = () => {
    return useContext<MapContextValue>(MapContext)
}

export { MapContext, MapProvider, useMapContext }