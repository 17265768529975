import ListView from 'components/ListView/ListView'
import Tile from 'components/Tiles/Tile'
import { usePersons } from 'Persons/PersonApi'
import { useEffect, useMemo, useRef, useState } from 'react'
import styled from 'styled-components'
import tt from '@tomtom-international/web-sdk-maps';
import PersonListItem from 'Persons/PersonListItem'
import usePersonParams from 'Persons/usePersonParams'
import Checkbox from 'components/checkbox/Checkbox'
import usePersonMarker from 'Persons/usePersonMarker'

const Layout = styled.div`
    flex-grow: 1;
    display: grid;
    grid-template-columns: minmax(min-content, 25em) 1fr;
    grid-template-rows: auto 1fr 1fr;
    grid-template-areas:
        "list-header list-header"
        "list map"
        "list map";

    row-gap: ${props => props.theme.spacing.hMedium};
    padding: ${props => props.theme.spacing.vMedium} ${props=> props.theme.spacing.hMedium};
`

const ListHeaderArea = styled.div`
    grid-area: list-header;
`

const ListArea = styled.div`
    grid-area: list;
    overflow: auto;
    direction: rtl;
`

const MapArea = styled.div`
    grid-area: map;
`

export default function PersonView() {

    const mapElement = useRef();
    const [map, setMap] = useState(null);
    const params = usePersonParams()
    const persons = usePersons(params.value)
    const [highlightedPerson, setHighlightedPerson] = useState(null)

    const associatedPersons = useMemo(() => {
        if(!highlightedPerson) {
            return []
        }
        var assignedEmployees = highlightedPerson?.customer?.assignments?.map(assignment => assignment.employee_id) || []
        var assignedCustomers = highlightedPerson?.employee?.assignments?.map(assignment => assignment.customer_id) || []
        return [
            ...assignedEmployees,
            ...assignedCustomers
        ]
    }, [highlightedPerson])


    const { marker } = usePersonMarker(persons.data, map)

    useEffect(() => {
        if(highlightedPerson) {
            var highlightedMarker = marker[highlightedPerson.id]
            highlightedMarker.getElement().classList.add('active')
            return () => {
                highlightedMarker.getElement().classList.remove('active')
            }
        }
    }, [marker, highlightedPerson])

    useEffect(() => {
        var selectedMarker = associatedPersons.map(personId => marker[personId]).filter(marker => marker)
        selectedMarker.forEach(marker => {
            marker.getElement().classList.add('active')
        })
        return () => {
            selectedMarker.forEach(marker => {
                marker.getElement().classList.remove('active')
            })
        }
    }, [marker, associatedPersons])


     useEffect(() => {
        let map = tt.map({
            key: process.env.REACT_APP_TOM_TOM_API_KEY,
            container: mapElement.current,
            center: [7.2, 51.5],
            zoom: 10,
            language: 'DE'
        });

        setMap(map);

        return () => {
            map.remove()
            setMap(null)
        };
    }, [setMap, mapElement]);

    return (
        <Layout>
            <ListHeaderArea>
                <Tile>
                    <Checkbox 
                        label='Kunden'
                        value={params.getProperty('isCustomer').value}
                        onChange={({value}) => params.setProperty('isCustomer', value ? true : undefined)}
                    />
                    <Checkbox
                        label='Mitarbeiter'
                        value={params.getProperty('isEmployee').value}
                        onChange={({ value }) => params.setProperty('isEmployee', value ? true : undefined)}
                    />
                </Tile>
            </ListHeaderArea>
            <ListArea>
                <Tile>
                    <ListView
                        data={persons.data}
                        listItem={(person) => (
                            <div
                                onMouseEnter={() => setHighlightedPerson(person)}
                                onMouseLeave={() => setHighlightedPerson(null)}
                            >
                                <PersonListItem person={person} />
                            </div>
                        )}
                        Separator={() => null}
                    />
                </Tile>
            </ListArea>
            <MapArea ref={mapElement}/>
        </Layout>
    )
}