import React, { useContext, useMemo }  from "react"
import useLookup from "Collections/useLookup";
import usePersonUtility from "Persons/usePersonUtility";
import { usePersons } from "Persons/PersonApi";
import { UseQueryResult } from '@tanstack/react-query';
import Person from "Persons/Person";

type CustomerContextValue = UseQueryResult<Person[], Error> & {
    archived: Person[],
    active: Person[]
}

const CustomerContext = React.createContext<CustomerContextValue | any>({})

const DEFAULT_PARAMS = {
    is_customer: true,
}

const CustomerProvider = ({ params = DEFAULT_PARAMS, ...props}) => {

    const customers = usePersons(params)
    const idLookup = useLookup("customer_id", customers.data)
    const { isActive } = usePersonUtility()

    const value = useMemo(() => {
        return {
            ...customers,
            idLookup,
            archived: customers?.data?.filter(customer => !isActive(customer)),
            active: customers?.data?.filter(customer => isActive(customer)),
        }
    }, [customers])

    return (
        <CustomerContext.Provider
            {...props}
            value={value}
        />
    )
}

const useCustomerContext = () => {
    return useContext<CustomerContextValue>(CustomerContext)
}

export { CustomerContext, CustomerProvider, useCustomerContext }