import FirstLevelNavigation from 'components/Navigation/FirstLevelNavigation';
import styled from 'styled-components';

const Container = styled.div`
    height: 100vh;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    background-color: #e7e7e7;
`

const Content = styled.div`
    display: flex;
    flex-direction: column;
    min-height: 0px;
    flex-grow: 1;
`

const Screen = ({ children, ...props }) => {
    return (
        <Container {...props}>
            <FirstLevelNavigation />
            <Content>
                {children}
            </Content>
        </Container>
    )
}

export default Screen