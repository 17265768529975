
import DateLabel from "components/labels/DateLabel";
import { useMemo } from "react";
import TableCell from "components/table/Cells/TableCell";
import Label from "components/labels/Label";

export const useAbsenceTableColumns = (hidden = [])  => {
    const columns = useMemo(() =>
        [
            {
                Header: 'Mitarbeiter',
                accessor: d => d,
                Cell: ({value, ...props}) => (
                    <TableCell
                        {...props}
                        renderLabel={() => (
                            <Label>{`${value.employee?.first_name} ${value.employee?.last_name}`}</Label>
                        )}
                    />               
                )
            },
            {
                Header: 'Zeitraum von',
                accessor: d => d.begin_date,
                Cell: ({ value, ...props }) => (
                    <TableCell
                        {...props}
                        renderLabel={() => (<DateLabel date={value}/>)}
                    />
                )
            },
            {
                Header: 'Zeitraum bis',
                accessor: d => d.end_date,
                Cell: ({ value, ...props}) => (
                    <TableCell
                        {...props}
                        renderLabel={() => {
                            return value ?
                                <DateLabel date={value}></DateLabel>
                                :
                                "Offen"
                        }}
                    />
                )
            },
            {
                Header: 'Grund',
                accessor: d => d.type_label,
                Cell: ({ value, ...props }) => (
                    <TableCell
                        {...props}
                        renderLabel={() => (<Label>{value}</Label>)}
                    />
                )
            },
        ].filter(column => !hidden.find(header => column.Header === header)),
        [hidden]
    );

    return { columns };
}