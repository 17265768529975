import ClickableOpacity from "components/Decorator/ClickableOpacity"
import Row from "components/Row"
import styled from 'styled-components';
import Theme from 'Theming/Theme';
import Icon from "./Icon";
import { Text } from "components/labels/Label";

const Content = styled.div`
`

const IconWrapper = styled.div`
    height: 19.2px;
    display: flex;
    align-items: center;
`

export interface IconRowProps {
    onClick?: () => any,
    text?: string,
    iconPath:string,
    iconColor?:string,
    children?:React.ReactNode
}

export default function IconRow({ 
    onClick, 
    text, 
    iconPath,
    iconColor=Theme.colors.primary,
    children,
    ...props
}: IconRowProps) {
    return (
        <ClickableOpacity onClick={onClick} {...props}>
            <Row gap={Theme.spacing.hSmall}>
                <IconWrapper>
                    <Icon
                        size='16px'
                        path={iconPath}
                        color={iconColor}
                    />
                </IconWrapper>
                <Content>
                    <Text>{ text}</Text>
                    { children }
                </Content>
            </Row>
        </ClickableOpacity>
    )
}