import PersonListItem from "Persons/PersonListItem";
import { useEffect, useMemo, useRef, useState } from "react";
import SideBar from "Sidebar/SideBar";
import { TabPanel } from "components/tabs/Tabs";
import ScrollView from "components/ScrollView";
import ListView from "components/ListView/ListView";
import useTabs from "components/tabs/useTabs";
import TabRow from "components/tabs/TabRow";
import DefaultTab from "components/tabs/DefaultTab";
import { FlexGrow } from "components/Layout/Flex";
import SearchField from "components/SearchField";
import Spacer from "components/Spacer/Spacer";
import CareLevelFilter from "../Customer/Filter/CareLevelFilter";
import { FaPlus } from "react-icons/fa";
import usePersonUtility from "Persons/usePersonUtility";
import Row from "components/Row";
import Column from "components/Column";
import IconButton from "components/Icons/IconButton";
import Person from "Persons/Person";

interface Props {
    selected:Person,
    select?: (person:Person) => any
    persons: Person[],
    createPerson: () => any
}

export default function PersonSidebar({ selected, select, persons, createPerson}:Props) {
    
    const ref = useRef<HTMLElement>()
    const [query, setQuery] = useState("")
    const [selectedCareLevels, setSelectedCareLevels] = useState([])
    const tabs = useTabs();
    const { isActive } = usePersonUtility()

    const displayedPersons = useMemo(() => {
        const adjustedQuery = query.toLowerCase()
        const careLevels = selectedCareLevels.map(c => c.value)
        return persons?.filter(person => {
            if (careLevels.length > 0 && !careLevels.includes(person.care_level)) {
                return false
            }
            return (
                person.contactDetails?.phoneNumbers?.find(number => number.number.includes(adjustedQuery)) ||
                `${person.first_name} ${person.last_name}`.toLowerCase().includes(adjustedQuery) ||
                String(person.id).includes(adjustedQuery)
            )
        })
    }, [query, persons, selectedCareLevels])


    const activePersons = useMemo(() => {
        return displayedPersons ? displayedPersons?.filter(c => isActive(c)) : undefined
    }, [displayedPersons])

    const archivedPersons = useMemo(() => {
        return displayedPersons ? displayedPersons.filter(c => !isActive(c)) : undefined
    }, [displayedPersons])

    useEffect(() => {
        if (!displayedPersons || tabs.selected || !selected || !archivedPersons) {
            return
        } 
        tabs.select(archivedPersons.some(c => c.id == selected.id) ? 'Archiv' : 'Aktuell');      
    }, [selected, tabs.select, tabs.selected, archivedPersons, displayedPersons])


    return (
        <>
            <SideBar>
                <Spacer medium>
                    <Row gap='0.5em'>
                        <Column grow gap='0.25em'>
                            <SearchField value={query} onChange={(event) => setQuery(event.target.value)} /> 
                            <CareLevelFilter
                                selectedCareLevels={selectedCareLevels}
                                setSelectedCareLevels={setSelectedCareLevels}
                            />
                        </Column>
                        <Spacer vSmall>
                            <IconButton Icon={FaPlus} onClick={createPerson} /> 
                        </Spacer>
                    </Row>
                </Spacer>
                <TabRow>
                    <FlexGrow>
                        <DefaultTab 
                            active={tabs.isSelected('Aktuell')} 
                            label={`Aktuell [${activePersons?.length || 0}]`}
                            onClick={() => tabs.select('Aktuell')}
                        />
                    </FlexGrow>
                    <FlexGrow>
                        <DefaultTab
                            active={tabs.isSelected('Archiv')}
                            label={`Archiv [${archivedPersons?.length || 0}]`}
                            onClick={() => tabs.select('Archiv')}
                        />
                    </FlexGrow>
                </TabRow>
                <ScrollView direction='rtl'>
                    <TabPanel active={tabs.isSelected('Aktuell')}>
                        <ListView 
                            data={activePersons} 
                            listItem={(person) => (
                                <PersonListItem 
                                    ref={selected?.id === person?.id ? ref : undefined}
                                    person={person}
                                    onClick={() => select(person)}
                                    marked={selected?.id === person?.id}
                                />
                            )}
                        />
                    </TabPanel>
                    <TabPanel active={tabs.isSelected('Archiv')} >
                        <ListView 
                            data={archivedPersons} 
                            listItem={(person) => (
                                <PersonListItem
                                    person={person}
                                    onClick={() => select(person)}
                                    marked={selected?.id === person?.id}
                                />
                            )}
                        />
                    </TabPanel>
                </ScrollView>
            </SideBar>
        </>
    );
}