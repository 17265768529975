import IconRow from "components/Icons/IconRow";
import PhoneNumber from "./PhoneNumber";
import ListView from "components/ListView/ListView";
import { Text } from "components/labels/Label";

interface Props {
    phoneNumbers: PhoneNumber[],
    onClick?: () => any
}

export default function PhoneNumberIconRow({phoneNumbers, onClick}:Props) {
    if(phoneNumbers.length === 0) {
        return null
    }

    return (
        <IconRow iconPath='fa/FaPhone' onClick={onClick}>
            <ListView
                data={phoneNumbers}
                Separator={() => null}
                listItem={(data) => (
                    <Text>{`${data.number} (${data.type_label})`}</Text>
                )}
            />
        </IconRow>
    )
}