import { FaHome, FaMoneyBillWave, FaBlind, FaUsers, FaTable, FaMapMarkedAlt, FaPowerOff, FaList, FaDatabase, FaMagic } from 'react-icons/fa';
import Navbar, { NavbarLogo } from 'components/Navigation/Navbar';
import logo from './../../assets/logo.png';
import { useContext, useMemo } from 'react';
import { AuthContext } from 'Login/AuthContext';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import FlexFill from 'components/FlexFill';
import useBills from 'Bills/useBills';
import Theme from 'Theming/Theme';

const StyledNavbar = styled(Navbar)`
    grid-area: navigation;
`

const NavigationItemContainer = styled.div`
    display: flex;
    flex-direction: row;
    height: 100%;
    margin-left: 1rem;
`

const BarItem = styled.div`
    display: flex;
    flex-direction: column;
    height: 80%;
    align-items: center;
    white-space: nowrap;
    color: #f7f7f7;
    padding: 0.5rem 1rem;
    boxSizing: 'border-box';

    svg {
        height: 35px;
        width: 35px;
    }
`

const ItemLabel = styled.span`
   text-decoration: none;
`

const NavItem = styled(NavLink)`
    transition: 0.25s;
    border-top: 4px solid transparent;
    border-bottom: 4px solid transparent;
    text-decoration: none;
    position: relative;

    
    &:hover {
        background-color: #00000033;
        border-color: transparent;
        cursor: pointer;
    }

    &.active {
        border-bottom-color: #fff;
        background-color: #00000066;
    }

`

const Badge = styled.span`
    position: absolute;
    background-color: ${Theme.whiteColor};
    left: 65%;
    top: 10%;
    font-size: 0.75em;
    border-radius: 4px;
    padding: 0.1em 0.3em;
    color: ${Theme.textColor};
`


export default function FirstLevelNavigation(props) {
    const { signout } = useContext(AuthContext)
    const logout = () => {
        signout()
    }

    const { data:unpaidBills } = useBills({unsettled: true})
    const todoBadge = useMemo(() => {
        return unpaidBills?.length
    }, [unpaidBills])

    const navbarData = [
        {
            path: "/",
            label: "Home",
            Icon: FaHome
        },
        {
            path: "/Finances",
            label: "Rechnungen",
            Icon: FaMoneyBillWave,
            badge: todoBadge
        },
        {
            path: "/Customers",
            label: "Kunden",
            Icon: FaBlind
        },
        {
            path: "/Employees",
            label: "Mitarbeiter",
            Icon: FaUsers
        },
        {
            path: "/Operations",
            label: "Einsätze",
            Icon: FaList
        },
        {
            path: "/Personalplanung",
            label: "Personalplanung",
            Icon: FaTable
        },
        {
            path: "/Map",
            label: "Kartenansicht",
            Icon: FaMapMarkedAlt,
        },
        {
            path: "/Data",
            label: "Datenbank",
            Icon: FaDatabase
        }
    ]

    return (
        <StyledNavbar {...props}>
            <NavbarLogo>
                <img src={logo} alt={""}/>
            </NavbarLogo>
            <NavigationItemContainer>
                {navbarData.map(({ Icon, ...data}, index) => (
                    <NavItem 
                        className={({isActive}) => isActive ? "active" : undefined}
                        key={index} 
                        to={data.path}
                    >
                        {
                            data.badge != null && data.badge != 0 && <Badge>{ data.badge} </Badge>
                        }
                        <BarItem>
                            <Icon/>
                            <ItemLabel>{data.label}</ItemLabel>
                        </BarItem>
                    </NavItem>
                ))}
            </NavigationItemContainer>
            <FlexFill/>
            <BarItem onClick={logout}>
                <FaPowerOff />
                <ItemLabel>Ausloggen</ItemLabel>
            </BarItem>
        </StyledNavbar>
    );
}
