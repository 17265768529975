import React from "react"
import { useContext, useState } from "react"


type HoverContextValue = {
    hovered: boolean,
    setHovered:(value:boolean) => any
}

const HoverContext = React.createContext<HoverContextValue>({
    hovered: false,
    setHovered: () => {}
})

const HoverProvider = (props) => {

    const [hovered, setHovered] = useState(false)

    return (
        <HoverContext.Provider value={{hovered, setHovered}} {...props}/>
    )
}

const useHoverContext = () => {
    return useContext<HoverContextValue>(HoverContext)
}

export { HoverContext, HoverProvider, useHoverContext }