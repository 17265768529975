import Address from "./Address";
import { useMemo } from "react";
import IconRow from "components/Icons/IconRow";

interface Props {
    address:Address,
    isPending?:boolean,
    onClick?: () => any
}

export default function AddressRow({ address, isPending=false, onClick, ...props }:Props) {

    const label = useMemo(() => {
        if (isPending) {
            return ""
        }
        if (!address) {
            return "Anschrift hinzufügen"
        }
    
        return `${address.street || ''}, ${address.postcode || ''} ${address.city || ''} ${address.district || ''}`

    }, [address, isPending])

    return (
        <IconRow
            onClick={onClick}
            iconPath="fa/FaMapMarkerAlt"
            text={label}
            {...props}
        />
    )
}