import useMarkers from "components/map/useMarkers";
import { useMemo } from "react";
import Person from "./Person";


export default function(persons:Person[], map) {
    const markerProps = useMemo(() => ({
        data: persons,
        map,
        accessor: (person) => person.id,
        markerClass: (person) => {
            var classes = []
            if(person?.customer) {
                classes.push('customer-marker')
            }
            if(person?.employee) {
                classes.push('employee-marker')
            }
            return classes.join(' ')
        },
        getGeoposition: (person) => {
            var result:[number, number] = [person.address?.longitude, person.address?.latitude]
            return result
        }
    }), [persons, map])
    
    return useMarkers(markerProps)
}