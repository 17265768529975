import { useCallback, useEffect, useMemo, useReducer, useState } from "react";
import tt from '@tomtom-international/web-sdk-maps';

type MarkerProps = {
    data: any,
    map:any,
    accessor: (data) => number, 
    getGeoposition: (data) => [number, number],
    markerClass?: (data) => string,
    onClick?: (data) => any,
    popupContent?: (data) => string
}

type MarkerEntry = tt.Marker & {
    id: number,
    marker:tt.Marker
}
type State = MarkerEntry[]

export default function useMarkers({
    data,
    map,
    accessor,
    getGeoposition,
    markerClass=(data) => '',
    onClick,
    popupContent
}:MarkerProps) {

    const reducer = (state:State=[], action) => {
        switch (action.type) {
            case 'clear-markers': {
                state.forEach(entry => {
                    entry.marker.remove();
                })
                return state;
            }
            case 'assign': {
                return action.value;
            }
            default:
                break;
        }
        return state;
    }

    const [marker, dispatch] = useReducer(reducer, []);

    const generatePopup = useCallback((data) => {
        if (typeof popupContent !== 'function') {
            return null
        }
        let popup = new tt.Popup({
            offset: {
                top: [0, 0],
                bottom: [0, -40],
                'bottom-right': [0, -70],
                'bottom-left': [0, -70],
                left: [25, -35],
                right: [-25, -35]
            },
            closeButton: false,
            closeOnClick: false,
        });
        popup.setHTML(popupContent(data));
        return popup;
    }, [popupContent])


    const clearMarkers = useCallback(() => {
        dispatch({
            type: 'clear-markers'
        })
    }, [dispatch])

    const createMarker = useCallback((data) => {
        if(!data) {
            return null
        }

        var geoposition = getGeoposition(data)
        geoposition[0] = geoposition[0] || 0
        geoposition[1] = geoposition[1] || 0
        let element = document.createElement('div');
        element.className = `marker ${markerClass(data)}`;

        let marker = new tt.Marker({element})
            .setLngLat(geoposition)

        if(map) {
            marker.addTo(map)
        }
          
        if(typeof onClick === 'function') {
            marker.getElement().addEventListener("click", () => {
                onClick(data);
            });
        }

        var popup = generatePopup(data)
        if(popup) {
            marker.setPopup(popup);
        }

        return {
            marker,
            id: accessor(data)
        };
    }, [generatePopup, onClick, getGeoposition, map, markerClass]);

    const createMarkers = useCallback((data) => {
        clearMarkers();
        let newMarkers = data
            .map(item => createMarker(item))
            .filter(marker => marker != null)

        dispatch({
            type:'assign',
            value: newMarkers
        })
    }, [createMarker, dispatch, clearMarkers]);

    useEffect(() => {
        if(Array.isArray(data)) {
            createMarkers(data);
        }
        else if(data) {
            createMarkers([data]);
        }
        else {
            clearMarkers();
        }
    }, [data, createMarkers, clearMarkers]) 
    
    const dictionary = useMemo(() => {
        return marker.reduce((dictionary, value) => {
            dictionary[value.id] = value.marker
            return dictionary
        }, {})
    }, [marker])

    return {
        marker:dictionary
    }
}