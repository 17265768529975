import tt from "@tomtom-international/web-sdk-maps"

export type MapMarkerProps = {
    id: number,
    geoposition: [number, number],
    markerClass?: string,
    onClick?: () => any,
    popupHtml?: string
}

export class MapMarker extends tt.Marker {
    id: number
}

export function generateMarker({
    id,
    geoposition,
    markerClass='',
    onClick,
    popupHtml
}:MapMarkerProps) : MapMarker {

    var element = document.createElement('div');
    element.className = `marker ${markerClass}`;
    let marker: MapMarker = new MapMarker({element})
    marker.id = id

    if(!geoposition) {
        return null
    }

    marker.setLngLat(geoposition)

    if (typeof onClick === 'function') {
        marker.getElement().addEventListener("click", () => {
            onClick();
        });
    }

    if (popupHtml) {
        var popup = generatePopup(popupHtml)
        marker.setPopup(popup);
    }

    return marker
}

function generatePopup(htmlString) : tt.Popup {
    let popup = new tt.Popup({
        offset: {
            top: [0, 0],
            bottom: [0, -40],
            'bottom-right': [0, -70],
            'bottom-left': [0, -70],
            left: [25, -35],
            right: [-25, -35]
        },
        closeButton: false,
        closeOnClick: false,
    });
    popup.setHTML(htmlString);
    return popup;
}