import styled from 'styled-components'
import { useHoverContext } from './HoverContext'
import React, { useMemo } from 'react'

type ContainerProps = {
    opacity: number
}

const Container = styled.div<ContainerProps>`
    opacity: ${props => props.opacity};
    transition: 0.2s
`

type HoverOpacityProps = {
    idleOpacity?: number,
    hoverOpacity?: number,
    children?: React.ReactNode
}

export default function HoverOpacity({ idleOpacity=0.0, hoverOpacity=1.0, ...props }:HoverOpacityProps) {

    const { hovered } = useHoverContext()

    const opacity = useMemo(() => {
        return hovered ? hoverOpacity : idleOpacity
    }, [hovered, idleOpacity, hoverOpacity])

    return (
        <Container 
            opacity={opacity}
            {...props}
        />
    )
}