import React, { useContext, useMemo } from 'react';
import useLookup from 'Collections/useLookup';
import usePersonUtility from 'Persons/usePersonUtility';
import { usePersons } from 'Persons/PersonApi';
import Person from 'Persons/Person';
import { UseQueryResult } from '@tanstack/react-query';


type EmployeeContextValue = UseQueryResult<Person[], Error> & {
    archived: Person[],
    active: Person[]
}

const EmployeeContext = React.createContext<EmployeeContextValue|any>({})

const DEFAULT_PARAMS = {
    is_employee: true
}

const EmployeeProvider = ({ params = DEFAULT_PARAMS, ...props }) => {

    const employees = usePersons(params)
    const idLookup = useLookup('employee_id', employees.data)
    const { isActive } = usePersonUtility()

    const value = useMemo(() => {
        return {
            ...employees,
            idLookup,
            archived: employees?.data?.filter(employee => !isActive(employee)),
            active: employees?.data?.filter(employee => isActive(employee)),
        }
    }, [employees])

    return (
        <EmployeeContext.Provider
            value={value}
            { ...props }
        />
    )
}

const useEmployeeContext = () => {
    return useContext<EmployeeContextValue>(EmployeeContext)
}

export { EmployeeContext, EmployeeProvider, useEmployeeContext }
